import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { weightUnitLabels } from '../../constants/labels';
import { getQuestioner } from '../../actions/questioner';
import styles from './ChatGPTUsers.module.css';

const ChatGPTUsers = (props) => {
  const { getQuestioner, questionerData } = props;

  useEffect(() => {
    getQuestioner();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        Chat GPT
      </h1>
      <table className="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Gender</th>
          <th>Weight</th>
          <th>Height</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        {questionerData?.map((questionerItem) => (
          <tr key={questionerItem.id}>
            <td>
              <Link className={styles.link} to={`/chat-gpt/${questionerItem.id}`} target="_blank">
                {questionerItem.name}
              </Link>
            </td>
            <td>{questionerItem.email}</td>
            <td>{questionerItem.gender === 'man' ? 'Male' : 'Female'}</td>
            <td>
              {questionerItem.weight} {weightUnitLabels[questionerItem.weight_unit]}
            </td>
            <td>
              {questionerItem.height} {questionerItem.height_unit === 'cm' ? 'cm' : 'feet'}
            </td>
            <td>{moment(questionerItem.created_at).format('DD/MM/yyyy HH:mm')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  questionerData: store.questioner.questionerData,
});

const mapDispatchToProps = {
  getQuestioner,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGPTUsers);