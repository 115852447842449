import React from 'react';
import { Field } from 'formik';
import cn from 'classnames';

import styles from './FormControls.module.css';

export const Input = (props) => {
  const { label, type, placeholder, className } = props;

  return (
    <Field {...props}>
      {({ field, meta }) => (
        <div>
          {label && <div className={styles.label}>{label}</div>}
          <input
            className={cn(styles.input, className)}
            type={type}
            placeholder={placeholder}
            {...field}
          />
          {meta.touched && meta.error && (
            <div className={styles.validationMessage}>{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};