import {
  SET_QUESTIONERS,
  SET_QUESTIONER_DETAILS,
  SET_REMIND_ME,
  SET_STRICT_MENU,
  SET_CHAT_GPT_DETAILS,
} from '../actions/questioner';

let initialState = {
  questionerData: [],
  questionerDetails: null,
  chatGPTDetails: null,
  remindMeData: [],
  strictMenuData: [],
};

const questioner = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONERS:
      return {
        ...state,
        questionerData: action.payload,
      };

    case SET_QUESTIONER_DETAILS:
      return {
        ...state,
        questionerDetails: action.payload,
      };

    case SET_CHAT_GPT_DETAILS:
      return {
        ...state,
        chatGPTDetails: action.payload,
      };

    case SET_REMIND_ME:
      return {
        ...state,
        remindMeData: action.payload,
      };

    case SET_STRICT_MENU:
      return {
        ...state,
        strictMenuData: action.payload,
      };

    default:
      return state;
  }
};

export default questioner;
