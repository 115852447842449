export const getFieldFromURLParams = (field) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(field);
};

export const getUserData = () => {
  const userData = localStorage.getItem('user');

  if (userData) {
    return JSON.parse(userData);
  }

  return null;
};

export const setUserData = (userData) => {
  console.log('===SET USER DATA', userData);
  localStorage.setItem('user', JSON.stringify(userData));
};


export const updateUserData = (userData) => {
  const oldUserData = getUserData();
  const newUserData = {
    ...oldUserData,
    ...userData,
  };
  setUserData(newUserData);
};
