import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { login } from '../../actions/auth';
import { Input } from '../../components/FormsControls/FormControls';
import styles from './Login.module.css';

const LoginSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Password too short').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const Login = ({ login }) => (
  <div className={styles.wrapper}>
    <Formik
      onSubmit={login}
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
    >
      <Form className={styles.form}>

        <div className={styles.title}>
          Please log in
        </div>
        <Input
          className={styles.input}
          name="email"
          type="text"
          placeholder="Email address"
        />
        <Input
          className={styles.input}
          name="password"
          type="password"
          placeholder="Password"
        />
        <button className={styles.submit}>Sign in</button>
      </Form>
    </Formik>
  </div>
);

const mapDispatchToProps = { login };

export default connect(null, mapDispatchToProps)(Login);
