import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { countries } from '../../constants/labels';
import { getRemindMe } from '../../actions/questioner';

const RemindMe = (props) => {
  const { getRemindMe, remindMeData } = props;

  useEffect(() => {
    getRemindMe();
  }, []);

  return (
    <div className="wrapper">
      <h1>
        Questioner
      </h1>
      <table className="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Country</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        {remindMeData?.map((remindMeItem) => (
          <tr key={remindMeItem.id}>
            <td>
              {remindMeItem.name}
            </td>
            <td>{remindMeItem.email}</td>
            <td>{countries[remindMeItem.country]}</td>
            <td>{moment(remindMeItem.created_at).format('DD/MM/yyyy HH:mm')}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  remindMeData: store.questioner.remindMeData,
});

const mapDispatchToProps = {
  getRemindMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(RemindMe);