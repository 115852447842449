import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { weightUnitLabels } from '../../constants/labels';
import { getChatGPTDetails } from '../../actions/questioner';
import { countries, physicalActivityLabel, goalLabel} from '../../constants/labels';
import styles from './ChatGPTDetails.module.css';

const ChatGPTDetails = (props) => {
  const {
    getChatGPTDetails,
    chatGPTDetails,
  } = props;

  const { id } = useParams();
  const iframeRef = useRef(null);
  // const totalNutrients = {};

  useEffect(() => {
    getChatGPTDetails(id);
  }, []);

  const handleIframeLoad = () => {
    if (iframeRef.current) {
      const contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight;
      iframeRef.current.style.height = `${contentHeight}px`;
    }
  };

  if (!chatGPTDetails) {
    return false;
  }

  const totalPrice = chatGPTDetails.chatGPT.reduce((totalPrice, promptData) => {
    console.log('===promptData', promptData);
    return totalPrice + promptData.price;
  }, 0).toFixed(4);

  return (
    <div className={styles.wrapper}>
      <h1>
        ChatGPT prompts
      </h1>
      <div className={styles.chatGPTDetails}>
        <div className={styles.leftPart}>
          <div className={styles.infoRow}>
            Name: {chatGPTDetails.name}
          </div>
          <div className={styles.infoRow}>
            Email: {chatGPTDetails.email}
          </div>
          <div className={styles.infoRow}>
            Country: {countries[chatGPTDetails.country]}
          </div>
          <div className={styles.infoRow}>
            Gender: {chatGPTDetails.gender === 'man' ? 'Male' : 'Female'}
          </div>
          <div className={styles.infoRow}>
            Date of birth: {moment(chatGPTDetails.birth_date).format('DD/MM/YYYY')}
          </div>
          <div className={styles.infoRow}>
            Weight: {chatGPTDetails.weight} {weightUnitLabels[chatGPTDetails.weight_unit]}
          </div>
          <div className={styles.infoRow}>
            Height: {chatGPTDetails.height} {chatGPTDetails.height_unit === 'cm' ? 'cm' : 'feet'}
          </div>
          <div className={styles.infoRow}>
            Physical activity: {physicalActivityLabel[chatGPTDetails.physical_activity]}
          </div>
          <div className={styles.infoRow}>
            Exclude meals: {chatGPTDetails.exclude_meals || '-'}
          </div>
          <div className={styles.infoRow}>
            Allergies: {chatGPTDetails.allergies || '-'}
          </div>
          <div className={styles.infoRow}>
            Goal: {goalLabel[chatGPTDetails.goal]}
          </div>
          <div className={styles.infoRow}>
            Calories: {chatGPTDetails.calories} kkal
          </div>
          <div className={styles.infoRow}>
            Protein: {chatGPTDetails.protein} grams
          </div>
          <div className={styles.infoRow}>
            Fat: {chatGPTDetails.fat} grams
          </div>
          <div className={styles.infoRow}>
            Carbohydrates: {chatGPTDetails.carbohydrates} grams
          </div>
          <div className={styles.infoRow}>
            Created at: {moment(chatGPTDetails.created_at).format('DD-MM-YYYY HH:mm')}
          </div>
        </div>
      </div>
      {!chatGPTDetails.menu && (
        <div className={styles.preline}>
          Processing..
        </div>
      )}
      <iframe
        ref={iframeRef}
        className={styles.menu}
        srcDoc={chatGPTDetails.menu}
        onLoad={handleIframeLoad}
      />
      <div className={styles.totalPrice}>Total price: ${totalPrice}</div>
      {chatGPTDetails.chatGPT.map((chatGPTData) => (
        <div className={styles.chatGPTBlock} key={chatGPTData.type}>
          <div className={styles.gptInfoBlock}>
            <div className={styles.typeTitle}>
              {chatGPTData.type}
            </div>
            <div className={styles.gptInfo}>
              Time: {chatGPTData.time.toFixed(2)} sek
            </div>
            <div className={styles.gptInfo}>
              Input tokens: {chatGPTData.input_tokens}
            </div>
            <div className={styles.gptInfo}>
              Output tokens: {chatGPTData.output_tokens}
            </div>
            <div className={styles.gptInfo}>
              Price: ${chatGPTData.price.toFixed(4)}
            </div>
          </div>
          <div className={styles.prompts}>
            <div className={styles.prompt}>{chatGPTData.prompt}</div>
            <div className={styles.answer}>{chatGPTData.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  chatGPTDetails: state.questioner.chatGPTDetails,
});

const mapDispatchToProps = {
  getChatGPTDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGPTDetails);
