import { get } from './api';

export const questionerAPI = {
  getQuestioners() {
    return get('admin/questioner');
  },

  getQuestionersDetails(id) {
    return get(`admin/questioner/${id}`);
  },

  getChatGPTDetails(id) {
    return get(`admin/chat-gpt/${id}`);
  },

  getRemindMe() {
    return get('admin/remind-me');
  },

  getStrictMenu() {
    return get('admin/strict-menu');
  },
};
