export const countries = {
  GB: 'United Kingdom',
  US: 'United States',
};

export const physicalActivityLabel = {
  passive: 'Just seat most of the time',
  moderate: 'Exercise 1-3 times per week',
  highly: 'Exercise 4-5 times per week',
  sportsmen: 'Professional sportsman',
};

export const goalLabel = {
  loose: 'Lose weight',
  balanced: 'Balanced my diet',
  gain: 'Gain weight',
};

export const weightUnitLabels = {
  kg: 'kg',
  lb: 'pounds',
  st: 'stones'
};