import { questionerAPI } from '../api/questioner';
import { actionWrapper } from './actionWrapper';

export const SET_QUESTIONERS = 'SET_QUESTIONERS';
export const SET_QUESTIONER_DETAILS = 'SET_QUESTIONER_DETAILS';
export const SET_CHAT_GPT_DETAILS = 'SET_CHAT_GPT_DETAILS';
export const SET_REMIND_ME = 'SET_REMIND_ME';
export const SET_STRICT_MENU = 'SET_STRICT_MENU';

export const getQuestioner = () => actionWrapper(async () => {
  const questionersData = await questionerAPI.getQuestioners();
  return {
    type: SET_QUESTIONERS,
    payload: questionersData,
  };
});

export const getQuestionerDetails = (id) => actionWrapper(async () => {
  const questionerDetailsData = await questionerAPI.getQuestionersDetails(id);
  return {
    type: SET_QUESTIONER_DETAILS,
    payload: questionerDetailsData,
  };
});

export const getChatGPTDetails = (id) => actionWrapper(async () => {
  const gatGPTDetailsData = await questionerAPI.getChatGPTDetails(id);
  return {
    type: SET_CHAT_GPT_DETAILS,
    payload: gatGPTDetailsData,
  };
});

export const getRemindMe = () => actionWrapper(async () => {
  const remindMeData = await questionerAPI.getRemindMe();
  return {
    type: SET_REMIND_ME,
    payload: remindMeData,
  };
});

export const getStrictMenu = () => actionWrapper(async () => {
  const strictMenuData = await questionerAPI.getStrictMenu();
  return {
    type: SET_STRICT_MENU,
    payload: strictMenuData,
  };
});