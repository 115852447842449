import React from 'react';
import moment from 'moment';

import styles from './Footer.module.css';

const currentYear = moment().format('YYYY');

const Footer = () => {
  return (
    <div className={styles.footer}>
      MeadWell Apps. All right reserved (c) {currentYear}
    </div>
  );
};

export default Footer;